import React, { useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet";
import loadable from "@loadable/component";
import { Router, Match } from "@reach/router";

import IndexSPA from "../components/IndexSPA";

var og_img = 'https://online.home-to-all.com' + require('../images/422socialimage.jpg');
var ios_icon = require('../images/ios_icon.png');
var ms_icon = require('../images/ms_icon.png');


const Index = (props) => {
  return (
     <>
        <Helmet
        title="HOME TO ALL 我們也可以這樣生活 / 臺北社宅藝術計劃線上展覽"
        meta={[
          {
            name: `description`,
            content: "一場都市設計和城市文化的創新運動。",
          },
          {
            property: `og:title`,
            content: "HOME TO ALL 我們也可以這樣生活 / 臺北社宅藝術計劃線上展覽",
          },
          {
            property: `og:description`,
            content: "一場都市設計和城市文化的創新運動。",
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: 'https://online.home-to-all.com' + require('../images/422socialimage.jpg')
          }

        ]}
        >
        </Helmet>
        <IndexSPA />
        {/* <Page /> */}
     </>
  )
};


// Index.propTypes = {}

export default Index;
